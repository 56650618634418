.SidebarLink {
  display: block;
}
.SidebarLink > li {
  position: relative;
  padding: 1rem 0;
  text-align: center;
}

.SidebarLink__icon {
  font-size: 1.5rem;
  color: #dde7f7;
}

a.SidebarLink.is-active > li {
  /* background: #222834; */
  /* Branding: */
  background: #050f1b;
  box-shadow: 0 1px 0 0 #2e3544, 0 -1px 0 0 #2e3544, inset 1px 0 2px 0 #171e28;
  /* border-left: 5px solid #4a90e2; */
  /* Branding: */
  border-left: 5px solid #c99700;
}

.SidebarLink > li:hover .SidebarLink__tooltip {
  visibility: visible;
  opacity: 1;
  transform: translate(72px, -50%);
}

.SidebarLink__tooltip {
  position: absolute;
  top: 50%;
  background: #2b333d;
  color: #dde7f7;
  padding: 8px 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 28px;
  transition: opacity 200ms, transform 200ms;
  white-space: nowrap;

  /* Overriden when collapsed and hovered */
  visibility: hidden;
  opacity: 0;
  transform: translate(40px, -50%);
}
