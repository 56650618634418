.AuthLayout {
  /* background-image: linear-gradient(
    141deg,
    #c9d6ff 0%,
    #e2e2e2 71%,
    #e2e2e2 100%
  ); */
  /* Branding: */
  background-color: #002855;
}
