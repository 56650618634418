.CircleIcon__icon-container {
  background-color: var(--blue-200);
  width: 2.5em;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  border-radius: 50%;
  color: var(--blue-700);
}

.CircleIcon__icon-container--gray {
  background-color: var(--gray-200);
  color: var(--gray-700);
}

.CircleIcon__icon-container--blue {
  background-color: var(--blue-200);
  color: var(--blue-700);
}

.CircleIcon__icon-container--teal {
  background-color: var(--teal-200);
  color: var(--teal-700);
}

.CircleIcon__icon-container--green {
  background-color: var(--green-200);
  color: var(--green-700);
}

.CircleIcon__icon-container--yellow {
  background-color: var(--yellow-200);
  color: var(--yellow-700);
}

.CircleIcon__icon-container--red {
  background-color: var(--red-200);
  color: var(--red-700);
}

.CircleIcon__icon-container--purple {
  background-color: var(--purple-200);
  color: var(--purple-700);
}
