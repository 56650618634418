/* .PortalLayout_box {
  border: 1px solid #CED2D5;
  box-shadow: 0 2px 5px 0 rgba(51,51,79,0.07);
} */

.PortalLayout__grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 64px 1fr;
  grid-template-columns: 64px 1fr;
  -ms-grid-rows: 64px 1fr;
  grid-template-rows: 64px 1fr;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.PortalLayout__nav {
  background: #fff;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / span 2;
  /* grid-column-start: 1; */
  /* grid-column-end: 3; */
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1 / span 1;
  /* grid-row-start: 1; */
  /* grid-row-end: 2; */
  border-bottom: 2px solid #dae1e5;
  z-index: 1;
}

.PortalLayout__logo-container {
  margin: 0 !important;
  /* width: 64px; */
  padding: 0 1.5rem;
  height: 64px;
  background: #fff;
  border-bottom: 2px solid #dae1e5;
  border-right: 1px solid #dae1e5;
}

.PortalLayout__sidebar {
  /* background: #2b333d; */
  /* background-color: var(--blue-900); */
  /* Branding: */
  background-color: #002855;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1 / span 1;
  /* grid-column-start: 1; */
  /* grid-column-end: 2; */
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2 / span 1;
  /* grid-row-start: 2; */
  /* grid-row-end: 3; */
  padding-top: 3rem;
  z-index: 1;
}

.PortalLayout__content-container {
  /* background: #ecf0f4; */
  /* background-color: var(--gray-100); */
  background-color: #f2f6fa;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / span 1;
  /* grid-column-start: 2; */
  /* grid-column-end: 3; */
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2 / span 1;
  /* grid-row-start: 2; */
  /* grid-row-end: 3; */
  overflow: scroll;
}

.PortalLayout__page-title-container {
  padding: 0 1.5rem;
}
h1.PortalLayout__page-title {
  font-size: 1.125rem;
  font-weight: 600;
}

/* Bulma override */
.box {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.07);
}
