.GradientHeader-primary {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  text-align: left;
  /* primary-500 to primary-400 gradient: */
  background: linear-gradient(
    106.42deg,
    hsl(207, 57%, 49%) 21.21%,
    hsl(207, 68%, 66%) 95.2%
  );
  padding: 48px 56px;
}
.GradientHeader-primary h1 {
  font-size: 28px;
  font-weight: 600;
  color: hsl(206, 100%, 97%); /* primary-100 */
  margin-bottom: 16px;
  line-height: 1;
}
.GradientHeader-primary h6 {
  font-size: 14px;
  font-weight: 600;
  color: hsl(208, 60%, 73%); /* primary-300 */
  line-height: 1;
}
