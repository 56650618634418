:root {
  /* Grays */
  --gray-900: hsl(215, 23%, 16%);
  --gray-800: hsl(214, 16%, 30%);
  --gray-700: hsl(213, 11%, 48%);
  --gray-600: hsl(214, 16%, 63%);
  --gray-500: hsl(209, 22%, 74%);
  --gray-400: hsl(208, 17%, 83%);
  --gray-300: hsl(210, 24%, 87%);
  --gray-200: hsl(207, 22%, 90%);
  --gray-100: hsl(210, 17%, 98%);

  /* Blues */
  --blue-900: hsl(206, 43%, 23%);
  --blue-800: hsl(207, 48%, 30%);
  --blue-700: hsl(207, 52%, 37%);
  --blue-600: hsl(208, 54%, 45%);
  --blue-500: hsl(207, 57%, 49%);
  --blue-400: hsl(207, 68%, 66%);
  --blue-300: hsl(208, 60%, 73%);
  --blue-200: hsl(207, 80%, 90%);
  --blue-100: hsl(206, 100%, 97%);

  /* Teals */
  --teal-900: hsl(179, 53%, 18%);
  --teal-800: hsl(178, 52%, 24%);
  --teal-700: hsl(177, 49%, 32%);
  --teal-600: hsl(175, 47%, 39%);
  --teal-500: hsl(174, 45%, 47%);
  --teal-400: hsl(175, 44%, 57%);
  --teal-300: hsl(175, 48%, 69%);
  --teal-200: hsl(175, 57%, 82%);
  --teal-100: hsl(177, 100%, 95%);

  /* Greens */
  --green-900: hsl(156, 56%, 20%);
  --green-800: hsl(145, 40%, 32%);
  --green-700: hsl(145, 47%, 42%);
  --green-600: hsl(145, 50%, 46%);
  --green-500: hsl(145, 50%, 50%);
  --green-400: hsl(145, 61%, 67%);
  --green-300: hsl(142, 68%, 75%);
  --green-200: hsl(142, 62%, 86%);
  --green-100: hsl(140, 80%, 94%);

  /* Yellows */
  --yellow-1000: hsl(15, 86%, 30%);
  --yellow-900: hsl(22, 82%, 39%);
  --yellow-800: hsl(29, 80%, 44%);
  --yellow-700: hsl(36, 77%, 49%);
  --yellow-600: hsl(42, 87%, 55%);
  --yellow-500: hsl(44, 92%, 63%);
  --yellow-400: hsl(48, 94%, 68%);
  --yellow-300: hsl(48, 95%, 76%);
  --yellow-200: hsl(48, 100%, 88%);
  --yellow-100: hsl(49, 100%, 96%);

  /* Reds */
  --red-900: hsl(359, 59%, 24%);
  --red-800: hsl(358, 48%, 30%);
  --red-700: hsl(359, 51%, 38%);
  --red-600: hsl(359, 55%, 45%);
  --red-500: hsl(359, 69%, 53%);
  --red-400: hsl(358, 68%, 66%);
  --red-300: hsl(359, 74%, 78%);
  --red-200: hsl(359, 85%, 87%);
  --red-100: hsl(0, 77%, 95%);

  /* Purples */
  --purple-900: hsl(229, 54%, 27%);
  --purple-800: hsl(229, 55%, 33%);
  --purple-700: hsl(229, 50%, 42%);
  --purple-600: hsl(229, 50%, 53%);
  --purple-500: hsl(229, 63%, 62%);
  --purple-400: hsl(226, 60%, 66%);
  --purple-300: hsl(222, 68%, 75%);
  --purple-200: hsl(223, 69%, 90%);
  --purple-100: hsl(223, 88%, 97%);
}
/*
@function palette($color, $shade) {
  @return map-get(map-get($colors, $color), $shade);
}

// yellow: (
//     900: hsl(43, 58%, 23%),
//     800: hsl(44, 54%, 33%),
//     700: hsl(45, 50%, 43%),
//     600: hsl(43, 51%, 53%),
//     500: hsl(41, 85%, 69%),
//     400: hsl(42, 83%, 76%),
//     300: hsl(43, 81%, 83%),
//     200: hsl(43, 78%, 91%),
//     100: hsl(44, 100%, 98%)
//   ), */
